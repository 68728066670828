import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { Upsell } from '@commerce/types/cart';
import { CommerceError } from '@commerce/utils/errors';
import { ProductBrand, ProductType } from '@components/product/enums';
import { useUI } from '@components/ui/context';
import { ErrorCode } from '@framework/api/utils/error-code';
import { useAddItem } from '@framework/cart';
import useCart from '@framework/cart/use-cart';
import useToastify from '@lib/hooks/useToastify';

export type AddToCartFn = (
  productId: string,
  sku?: string,
  productName?: string,
  type?: ProductType,
  premiumSource?: string,
  brand?: string,
  upsell?: Upsell, // premium upsell (from PDP or cart)
  isCartUpsell?: boolean // to track if item is added from cart upsell
) => Promise<boolean>;

interface Options {
  shouldOpenCartOverlay?: boolean;
  refreshCartOnFailure?: boolean;
}

export const useAddToCart = (options?: Options): [boolean, AddToCartFn] => {
  const { shouldOpenCartOverlay = true, refreshCartOnFailure = false } = options || {};
  const [loading, setLoading] = useState(false);
  const { mutate } = useCart();

  const { openCartOverlay } = useUI();
  const { t } = useTranslation(['common', 'cart']);

  const addItem = useAddItem();
  const toast = useToastify();

  const onAddToCart: AddToCartFn = async (
    productId: string,
    sku?: string,
    productName?: string,
    type = ProductType.PHYSICAL,
    premiumSource?: string,
    brand = ProductBrand.TILE,
    upsell = undefined,
    isCartUpsell = false
  ) => {
    if (!loading) {
      setLoading(true);
      try {
        await addItem({ productId, sku, productName, type, premiumSource, brand, upsell, isCartUpsell });
        // don't open overlay for L360 premium, redirect to OTP steps
        if (shouldOpenCartOverlay && brand !== ProductBrand.L360) {
          openCartOverlay();
        }
        return true;
      } catch (err) {
        const errorCode = (err instanceof CommerceError && err?.errors[0]?.code) ?? 'default';
        const msg = t(`cart:error.add.${errorCode}`);
        if (errorCode === ErrorCode.CART_MULTIPLE_PREMIUM) {
          toast.success(msg);
        } else {
          toast.error(msg);
        }

        if (refreshCartOnFailure) {
          mutate();
        }
      } finally {
        setLoading(false);
      }
    }
    return false;
  };

  return [loading, onAddToCart];
};
