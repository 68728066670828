import { useRouter } from 'next/router';
import { ReactText, useEffect, useRef } from 'react';
import { toast, ToastContent, ToastOptions } from 'react-toastify';

// naming to avoid confusion with `useToast` (beta hooks from react-toastify https://github.com/fkhadra/react-toastify/pull/421)
export default function useToastify() {
  const toastId = useRef<ReactText | null>(null);
  const registered = useRef<boolean>(false);
  const router = useRouter();

  const sendToast =
    (notify = toast.info) =>
    (msg: ToastContent, options?: ToastOptions | undefined) => {
      if (!toastId.current || !toast.isActive(toastId.current!)) {
        toastId.current = notify(msg, { toastId: 'toast-message', ...options });
      }
    };

  useEffect(() => {
    const handleRouteChange = () => {
      if (toastId) {
        toast.dismiss();
      }
    };

    if (!registered.current && router.events && router.beforePopState) {
      // on navigate listener
      router.events.on('routeChangeStart', handleRouteChange);
      // on browser back listener
      router.beforePopState(() => {
        handleRouteChange();
        return true;
      });
      registered.current = true;
    }

    return () => {
      if (router.events) {
        router.events.off('routeChangeStart', handleRouteChange);
      }
      if (router.beforePopState) {
        router.beforePopState(() => true);
      }
    };
  }, [router]);

  return {
    info: sendToast(),
    success: sendToast(toast.success),
    warn: sendToast(toast.warn),
    error: sendToast(toast.error),
  };
}

export type Toastify = ReturnType<typeof useToastify>;
