import cn from 'classnames';
import { FC, InputHTMLAttributes } from 'react';

import { useId } from '@lib/use-id';

import s from './Input.module.css';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  onChange?: (...args: any[]) => any;
}

const Input: FC<Props> = (props) => {
  const { className, children: _, onChange, id, title, ...rest } = props;
  const inputId = useId(id ?? title);

  const rootClassName = cn(s.root, className);

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  return (
    <>
      {title && (
        <label htmlFor={inputId} className="sr-only text-white">
          {title}
        </label>
      )}
      <input
        id={inputId}
        name={inputId}
        className={rootClassName}
        onChange={handleOnChange}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        {...rest}
      />
    </>
  );
};

export default Input;
